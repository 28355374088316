import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'


const InputCounter = props => {
  const { parentRef, type, force, count } = props

  const [ length, setLength ] = useState(parentRef?.current?.value.length || 0)

  const updateCount = () => {
    if (parentRef && parentRef.current) {
      setLength(parentRef.current.value.length)
    }
  }

  useEffect(() => {
    if (parentRef && parentRef.current) {
      parentRef.current.addEventListener('input', updateCount)
      updateCount()
    }
    return () => {
      parentRef.current?.removeEventListener('input', updateCount)
    }
  }, [ parentRef.current ])

  switch (type) {
    case 'count-down':
      return (<span className="form-control-info">Character count: {length}/{count}</span>)
    default:
      return (<span className="form-control-info">Character{force ? ' Limit' : 's'}: {length}{force ? `/${count}` : ''}</span>)
  }
}

export default InputCounter

InputCounter.propTypes = {
  parentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) })
  ]),
  type: PropTypes.string,
  value: PropTypes.string,
  count: PropTypes.number,
  force: PropTypes.bool
}
