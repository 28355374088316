import React from 'react'
import PropTypes from 'prop-types'
import { WhatsappShareButton } from 'react-share'

import { getDisplayName } from '../../utils'
import QueryBuilder from './QueryBuilder'


function isMobileOrTablet() {
  return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent)
}

export function withWhatsApp(ShareButton) {
  return class WhatsAppShare extends React.Component {
    static displayName = `WhatsAppShare(${getDisplayName(ShareButton)})`

    static propTypes = {
      phone: PropTypes.string,
      url: PropTypes.string,
      className: PropTypes.string,
      component: PropTypes.object,
      classNames: PropTypes.array
    }

    addPhoneProp(element) {
      const { phone } = this.props
      let url = `https://${isMobileOrTablet() ? 'api' : 'web'}.whatsapp.com/send${(phone ? `?phone=${phone}` : '')}`
      if (this.props.url) {
        const qs = new QueryBuilder(this.props.url, true)
        qs.setParam('phone', phone)
        url = element.props.networkLink(qs.url(false), element.props.opts)
      }
      return url
    }

    render() {
      // eslint-disable-next-line no-unused-vars
      const { classNames, component: Component, ...props } = this.props
      const classes = `${this.props.className}`
      props.className = classes
      const elementsTree = (<ShareButton />).type.render(props)
      const newProps = {
        ...elementsTree.props,
        icon: props.icon,
        networkLink: this.addPhoneProp.bind(this, elementsTree)
      }
      const elementProps = Object.assign({}, elementsTree.props, newProps)
      const newElementsTree = React.cloneElement(elementsTree, elementProps, elementsTree.props.children)
      return newElementsTree
    }
  }
}

export default withWhatsApp(WhatsappShareButton)
