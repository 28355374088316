import merge from 'deepmerge'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'

import { logEvent, handleSubmitError, resolveError } from '../utils'
import { Scrollbar } from './ui/Scrollbars'
import { Button } from './ui/Button'
import CustomForm from './common/forms/CustomForm'
import FieldGroup from './common/forms/FieldGroup'
import Loader from './common/Loader'


class CleverComposeSidebar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      results: [],
      initvals: {},
      config: {
        fields: [
          {
            name: 'enhancement',
            input: 'Select',
            edit: true,
            label: 'AI Task',
            options: [
              { value: 'Create a marketing heading', label: 'Create a marketing heading' },
              { value: 'Rewrite my description', label: 'Rewrite my description' },
              { value: 'Create a facebook post', label: 'Write a facebook post' },
              { value: 'Create an instagram post', label: 'Write an instagram post' },
              { value: 'Create a tweet', label: 'Write a tweet' },
              { value: 'Fix grammar issues', label: 'Fix grammar issues' },
              { value: 'Rewrite my description', label: 'Rewrite my description' },
              { value: 'Write a description', label: 'Write a new description' }
            ]
          },
          {
            name: 'output',
            input: 'TextArea',
            edit: true,
            readonly: true,
            label: 'Output'
          }
        ]
      }
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleSubmitError = handleSubmitError.bind(this)
  }

  componentDidMount() {
    const { siblingform } = this.props
    const { config } = this.state
    const initvals = { ...siblingform.values }

    config.fields.forEach(field => {
      if (field.defaultvalue !== undefined && field.defaultvalue !== null) { initvals[field.name] = field.defaultvalue }
      if (field.twin && siblingform.values[field.name]) {
        initvals[field.name] = siblingform.values[field.name]
      }
    })

    this.setState({ initvals })

    if (this.el && this.props.sidebar !== 'clevercompose-sidebar') {
      setTimeout(() => {
        this.props.actions.toggleWideSidebar('clevercompose-sidebar')
      }, 50)
    }
  }

  componentWillUnmount() {
    this.props.actions.toggleWideSidebar()
  }

  handleSubmit(values, actions) {
    return new Promise((resolve, reject) => {
      const vals = { ...values } // Make a copy as we are mutating
      Object.keys(vals).forEach(k => {
        const field = this.state.config.fields.find(f => f.name === k)
        if (field && field.input === 'Float') { vals[k] = vals[k] ? parseFloat(vals[k]).toFixed(1) : vals[k] }
        if (vals[k] === null || vals[k] === '') { delete vals[k] }
      })
      logEvent('CLEVERCOMPOSE', { ...values })
      this.props.actions.fetchCleverCompose(vals, resolve, reject) // Perform the lookup
    }).then(r => {
      actions.setSubmitting(false)
      const { initvals } = this.state
      this.setState({ initvals: { ...initvals, output: r.result, enhancement: values.enhancement } })
    }).catch(e => {
      this.handleSubmitError(e, actions, this.form)
      this.props.actions.notifyUser({ title: 'Error', body: resolveError(e, this.state.config.fields), type: 'error' })
      actions.setSubmitting(false)
    })
  }

  render () {
    const { siblingform, actions } = this.props
    return (
      <div id="deeds-lookup-sidebar" ref={el => { this.el = el }} className="deeds-lookup-sidebar wide-sidebar">
        <div className="wide-sidebar-container">
          <Formik
            initialValues={{
              ...this.state.initvals
            }}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={this.handleSubmit}
            enableReinitialize={true}
          >{ formik => {
              this.form = formik
              return (
                <CustomForm
                  render={() => (
                    <div className="wide-sidebar-pane">
                      <div className="wide-sidebar-heading">
                        <h4>CleverCompose</h4>
                        <Button
                          type="button"
                          icon="#icon24-X-Large"
                          className="btn btn-none btn-icon btn-icon-24 btn-wide-sidebar-close"
                          onClick={() => { this.props.actions.toggleWideSidebar() }}
                        />
                      </div>
                      <Scrollbar
                        style={{ height: 'calc(100vh - 218px)' }}
                        renderView={({ style, ...props }) => <div {...props} style={{ ...style, position: 'relative', height: 'calc(100% + 15px)' }} className="scrollview"/>}
                      >
                        <div className="wide-sidebar-content">
                          <FieldGroup
                            card={false}
                            fields={this.state.config.fields.map(f => {
                              const newfield = merge({}, f)
                              if (newfield.twin) { newfield.readonly = true }
                              return newfield
                            })}
                            config={this.state.config}
                            creator={this.props.field.name}
                            modelname={this.props.deeds}
                            match={this.props.match}
                            required={this.state.required}
                            columns
                            collapsed={false}
                          />
                          <div className="wide-sidebar-footer">
                            {(formik.values.output) ? (
                              <Button
                                onClick={() => {
                                  navigator.clipboard.writeText(formik.values.output)
                                  this.props.actions.notifyUser({ title: 'Copied', body: 'Copied text to clipboard', type: 'success' })
                                }} // Required for type="button"
                                type="button" // This cannot be submit otherwise sibling form is submitted
                                disabled={formik.isSubmitting}
                                className="btn btn-primary">Copy Text</Button>
                            ) : null}
                            {(formik.values.output && [
                              'Rewrite my description',
                              'Fix grammar issues',
                              'Rewrite my description',
                              'Write a description'
                            ].includes(formik.values.enhancement)) ? (
                                <Button
                                  onClick={() => {
                                    if (siblingform.debounced) {
                                      siblingform.debounced(formik.values.output)
                                      actions.toggleWideSidebar()
                                    } else if (siblingform.setFieldValue) {
                                      siblingform.setFieldValue('description', formik.values.output).then(() => {
                                        siblingform.setFieldTouched('description')
                                        actions.toggleWideSidebar()
                                      })
                                    }
                                  }} // Required for type="button"
                                  type="button" // This cannot be submit otherwise sibling form is submitted
                                  disabled={formik.isSubmitting}
                                  className="btn btn-primary">Replace Description</Button>
                              ) : null}
                            {(formik.values.output && [
                              'Create a marketing heading'
                            ].includes(formik.values.enhancement)) ? (
                                <Button
                                  onClick={() => {
                                    siblingform.setFieldValue('marketing_heading', formik.values.output).then(() => {
                                      siblingform.setFieldTouched('marketing_heading')
                                      actions.toggleWideSidebar()
                                    })
                                  }} // Required for type="button"
                                  type="button" // This cannot be submit otherwise sibling form is submitted
                                  disabled={formik.isSubmitting}
                                  className="btn btn-primary">Replace Marketing Heading</Button>
                              ) : null}
                            <Button
                              onClick={formik.submitForm} // Required for type="button"
                              type="button" // This cannot be submit otherwise sibling form is submitted
                              disabled={formik.isSubmitting}
                              className="btn btn-primary">{formik.isSubmitting ? <Loader inline /> : 'Generate'}</Button>
                          </div>
                        </div>
                      </Scrollbar>
                    </div>
                  )}
                />
              )
            }}
          </Formik>
        </div>
      </div>
    )
  }
}

CleverComposeSidebar.propTypes = {
  config: PropTypes.object,
  field: PropTypes.object,
  siblingform: PropTypes.object,
  toggleLookup: PropTypes.func,
  sidebar: PropTypes.string,
  ownerfield: PropTypes.string,
  actions: PropTypes.object,
  user: PropTypes.object,
  deeds: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  match: PropTypes.object,
  cache: PropTypes.object
}

export default CleverComposeSidebar
